import React from "react";

const Dropdown = ({ setOpen, children, selected }) => {
  return (
    <div className="relative">
      <div className="absolute top-0 left-0 right-0 pb-10 z-20 mt-2">
        <div className="bg-gray-100 border border-gray-300 rounded-lg shadow-lg">
          <div className="sticky bg-gray-100 w-full top-0 pb-0 rounded-t-lg">
            <div className="relative">
              <div className="sticky top-0 z-30">
                <div className="absolute right-0">
                  <button
                    type="button"
                    className="p-3 text-pink-900 font-bold"
                    onClick={() => setOpen(false)}
                  >
                    x
                  </button>
                </div>
              </div>
              {children}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dropdown;
