import React, { useRef, useEffect } from "react";
import { ChevronDown } from "../shop-icons";
import Dropdown from "../dropdown";

export const Select = ({
  open,
  setOpen,
  label,
  children,
  selected,
  placeholder,
  className,
  hasError = false,
  closeOnSelect = false,
  scrollBackOnSelect = false,
}) => {
  const scrollTopRef = useRef(null);

  useEffect(() => {
    if (closeOnSelect) {
      setOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  useEffect(() => {
    if (scrollBackOnSelect) {
      if (open) {
        scrollTopRef.current = window.scrollY;
      } else if (scrollTopRef.current !== null) {
        window.scrollTo({
          behavior: "smooth",
          top: scrollTopRef.current,
        });
        scrollTopRef.current = null;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  let errorClass = hasError ? "ring ring-pink-900" : "";
  let openClass = open ? "rotate-180" : "rotate-0";

  return (
    <div className={`relative ${className}`}>
      <label className="block text-sm text-gray-700 mb-1">{label}</label>
      <button
        type="button"
        onClick={() => {
          setOpen((open) => !open);
        }}
        className={`w-full flex items-center justify-between px-4 py-4 bg-gray-100 border 
            border-gray-300 rounded-md text-gray-700 leading-4 text-sm text-left focus:outline-none 
            focus:border-gray-700 ${errorClass}`}
      >
        {placeholder}
        {children && (
          <span>
            <ChevronDown
              className={`w-2 text-gray-700 stroke-current transition-transform transform ${openClass}`}
            />
          </span>
        )}
      </button>
      {children && open && (
        <Dropdown setOpen={setOpen} selected={selected}>
          {children}
        </Dropdown>
      )}
    </div>
  );
};

export const BasicPicker = (props) => {
  const { items, select } = props;
  return (
    <div className="flex flex-col text-gray-700 divide-y divide-gray-300 justify-center">
      {items.map((item) => {
        let label = item.slug === "" ? item.value : item.slug;
        return (
          <button
            key={item._id}
            type="button"
            className="py-3 focus:outline-none"
            onClick={() => select(item)}
          >
            {label}
          </button>
        );
      })}
    </div>
  );
};

const ColorButton = (props) => {
  let { color, select } = props;
  return (
    <button
      type="button"
      onClick={() => select(color)}
      className="w-14 h-14 rounded-full focus:outline-none overflow-hidden place-self-start shadow-md"
    >
      <img
        src={color.image.secure_url}
        width={100}
        height={100}
        loading="lazy"
        className="h-full w-full object-cover"
        alt={color.value}
      />
    </button>
  );
};

const ColorPlaceholder = ({ selected }) => {
  return (
    <span className="inline-flex items-center space-x-2">
      <img
        src={selected.image.secure_url}
        width={100}
        height={100}
        loading="lazy"
        className="h-4 w-4 rounded-full object-cover"
        alt={selected.value}
      />
      <span className="capitalize">
        {selected.value}
        {selected.slug && selected.slug !== "" && ` - ${selected.slug}`}
      </span>
    </span>
  );
};

const SelectedContainer = ({ children }) => {
  return (
    <div className="sticky bg-gray-100 w-full top-0 p-4 pb-0 z-20">
      <div className="flex items-center space-x-4 border-b border-gray-500 pb-4">
        {children}
      </div>
    </div>
  );
};

const SelectedColor = ({ selected }) => {
  return (
    <SelectedContainer>
      <div className="w-20 h-20 rounded-full overflow-hidden flex-shrink-0 shadow">
        <img
          src={selected.image.secure_url}
          width={100}
          height={100}
          loading="lazy"
          className="h-full w-full object-cover"
          alt={selected.value}
        />
      </div>
      <div className="text-blue-900 text-lg lg:text-xl font-bold w-7/12 capitalize">
        {selected.value}
        {selected.slug && selected.slug !== "" && ` - ${selected.slug}`}
      </div>
    </SelectedContainer>
  );
};

export const ColorSelect = ({
  open,
  setOpen,
  label,
  selected,
  select,
  colors,
  lurexColors,
  hasError = false,
}) => {
  return (
    <Select
      open={open}
      setOpen={setOpen}
      label={label}
      placeholder={
        selected !== undefined ? (
          <ColorPlaceholder selected={selected} />
        ) : (
          "Choose here"
        )
      }
      hasError={hasError}
      className="lg:col-span-2"
    >
      {selected !== undefined && <SelectedColor selected={selected} />}
      <div className="flex justify-center">
        <div className="grid grid-cols-4 gap-4 p-4">
          {colors.map((color) => (
            <ColorButton color={color} select={select} key={color._id} />
          ))}
          {lurexColors && lurexColors.length > 0 && (
            <>
              <p className="col-span-4 text-sm font-semibold text-gray-700">
                Lurex Colors
              </p>
              {lurexColors.map((lurexColor) => (
                <ColorButton
                  color={lurexColor}
                  select={select}
                  key={lurexColor._id}
                />
              ))}
            </>
          )}
        </div>
      </div>
    </Select>
  );
};
