import React, { useState, useEffect, useRef } from "react";

const ImageCarousel = ({ images }) => {
  const sliderRef = useRef();
  const [position, setPosition] = useState(0);

  useEffect(() => {
    const slider = sliderRef.current;
    const image = slider.children[position];

    slider.scroll({
      left: image.offsetLeft,
    });
  }, [position]);

  return (
    <div className="w-full h-full relative">
      <div
        className="flex overflow-x-hidden bg-gray-300 shadow rounded-lg"
        ref={sliderRef}
      >
        {images.map((image, i) => (
          <div
            className="w-full h-300 lg:h-500 flex-shrink-0 flex-grow relative"
            key={i}
          >
            <img
              src={image.url}
              loading="lazy"
              className="w-full h-full rounded-lg object-cover"
              alt={i}
            />
          </div>
        ))}
      </div>
      <div className="absolute w-full h-full inset-0">
        <button
          className="absolute left-0 w-3/12 h-full flex items-center justify-start p-4 text-black font-display text-2xl cursor-pointer focus:outline-none opacity-40"
          onClick={() =>
            setPosition((i) => (i > 0 ? i - 1 : images.length - 1))
          }
        >
          {"<"}
        </button>
        <button
          className="absolute right-0 w-3/12 h-full flex items-center justify-end p-4 text-black font-display text-2xl cursor-pointer focus:outline-none opacity-40"
          onClick={() =>
            setPosition((i) => (i < images.length - 1 ? i + 1 : 0))
          }
        >
          {">"}
        </button>
        <div className="absolute left-0 right-0 bottom-0 w-full flex justify-center space-x-3 py-4">
          {images.map((image, i) => (
            <button
              key={i}
              className={`w-3 h-3 rounded-full border cursor-pointer focus:outline-none ${
                i === position
                  ? "bg-white border-white"
                  : "bg-black border-black opacity-40"
              }`}
              onClick={() => setPosition(i)}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default ImageCarousel;
