import React, { useState, useLayoutEffect } from "react";
import { Link, graphql, navigate } from "gatsby";
import useAuth from "../hooks/useAuth";
import ShopLayout from "../components/new-layout";
import Breadcrumbs from "../components/breadcrumbs";
import SizeGuide from "../components/size-guide";
import { Select, BasicPicker, ColorSelect } from "../components/selects";
import Img from "gatsby-image";
import Swal from "sweetalert2";

import {
  convertToSlug,
  convertToTwoDecimalCase,
  convertProductPriceToUserPrice,
} from "../utils/converters";
import { isBrowser } from "../utils/isBrowser";
import ImageCarousel from "../components/image-carousel";

const Product = ({ data }) => {
  const { state, handleAddToBag } = useAuth();
  const [color, setColor] = useState();
  const [size, setSize] = useState();
  const [pattern, setPattern] = useState();
  const [quantity, setQuantity] = useState(3);
  const [minQuantity, setMinQuantity] = useState(3);
  const [finalPrice, setFinalPrice] = useState(0);
  const [canAddToBag, setCanAddToBag] = useState(false);
  const [openSizePicker, setOpenSizePicker] = useState(false);
  const [openColorPicker, setOpenColorPicker] = useState(false);
  const [pickerChanged, setPickerChanged] = useState(false);
  const [gridPicker, setGridPicker] = useState([]);
  const [displayMode, setDisplayMode] = useState("grid");
  const [multipleSell, setMultipleSell] = useState(3);

  const checkIsMultiple = (number) => {
    var result = (number % multipleSell) / 100;

    if (result === 0) return true;
    return false;
  };

  useLayoutEffect(() => {
    setCanAddToBag(
      ((size || sizeVariant === undefined) &&
        (color || colorVariant === undefined) &&
        (pattern || patternVariant === undefined) &&
        quantity > 0 &&
        checkIsMultiple(quantity)) ||
        gridPicker.find((g) => g.quantity > 0 && checkIsMultiple(g.quantity))
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [size, color, pattern, quantity, pickerChanged]);

  useLayoutEffect(() => {
    let optionFields =
      product &&
      product !== undefined &&
      product.optionFields &&
      product.optionFields !== undefined
        ? product.optionFields
        : [];

    let displayModeOption = optionFields.find((o) => o.name === "displayMode");
    if (displayModeOption !== undefined) {
      setDisplayMode(displayModeOption.value);
    } else if (!(sizes && colors && sizes.length > 0 && colors.length > 0)) {
      setDisplayMode("singleSelect");
    }

    let multipleSellOption = optionFields.find(
      (o) => o.name === "multipleSell"
    );
    if (multipleSellOption !== undefined) {
      setMultipleSell(multipleSellOption.value);
      setQuantity(Number(multipleSellOption.value));
      setMinQuantity(Number(multipleSellOption.value));
    }
  }, []);

  const isAuthenticated =
    state !== undefined &&
    state.isLoggedIn &&
    state.isActive &&
    !state.isExpired;

  if (isBrowser && !isAuthenticated) {
    navigate("/");
    return null;
  }

  const product = data.product;
  const productPrice = convertProductPriceToUserPrice(
    state !== undefined ? state.groups : [],
    product
  );

  const colorVariant = product.variants.find((v) => v.name === "color");
  const sizeVariant = product.variants.find((v) => v.name === "size");
  const patternVariant = product.variants.find((v) => v.name === "pattern");
  const lurexVariant = product.variants.find((v) => v.name === "lurex");

  const colors =
    colorVariant !== undefined
      ? product.properties.filter((p) => p.relationKey === colorVariant._id)
      : null;

  const lurexColors =
    lurexVariant !== undefined
      ? product.properties.filter((p) => p.relationKey === lurexVariant._id)
      : null;

  const sizesProp =
    sizeVariant !== undefined
      ? product.properties
          .filter((p) => p.relationKey === sizeVariant._id)
          .sort((a, b) => a.value - b.value)
      : null;

  // hammer
  const sizes = [];
  if (sizesProp && sizesProp.length > 0) {
    let tripleZero = sizesProp.find((p) => p.value === "000");
    let doubleZero = sizesProp.find((p) => p.value === "00");
    let ageInternval = sizesProp.find((p) => p.value === "6m");
    let sizeInterval = sizesProp.find(
      (p) => p.value.split("-")[1] !== undefined
    );

    if (tripleZero && doubleZero) {
      sizes.push(tripleZero);
      sizes.push(doubleZero);

      sizesProp.forEach((s) => {
        if (s.value !== tripleZero.value && s.value !== doubleZero.value) {
          sizes.push(s);
        }
      });
    } else if (sizeInterval) {
      let splitedArray = sizesProp
        .map((s) => {
          let _value = s.value.split("-")[0];
          return {
            ...s,
            _value,
          };
        })
        .sort((a, b) => a._value - b._value);

      splitedArray.forEach((s) => sizes.push(s));
    } else if (ageInternval) {
      sizes.push(ageInternval);

      let splitedArray = sizesProp
        .filter((v) => v.value !== ageInternval.value)
        .map((s) => {
          let _value = s.value.split("y")[0];
          return {
            ...s,
            _value,
          };
        })
        .sort((a, b) => a._value - b._value);

      splitedArray.forEach((s) => sizes.push(s));
    } else {
      sizesProp.forEach((s) => sizes.push(s));
    }
  }

  const patterns =
    patternVariant !== undefined
      ? product.properties.filter((p) => p.relationKey === patternVariant._id)
      : null;

  const handleColorChanged = (item) => {
    setColor(item);
    setOpenColorPicker(false);
    setCalculetedFinalPrice(item._id, size !== undefined ? size._id : null);

    // set size when product have only one size
    if (sizes !== undefined && sizes.length === 1) setSize(sizes[0]);
  };

  const handleSizeChanged = (item) => {
    setSize(item);
    setOpenSizePicker(false);
    setCalculetedFinalPrice(item._id, color !== undefined ? color._id : null);
  };

  const handlePatternChanged = (e) => {
    setPattern(e.target.value);
    setCalculetedFinalPrice(
      e.target.value,
      size !== undefined ? size._id : null
    );

    // set size when product have only one size
    if (sizes !== undefined && sizes.length === 1) setSize(sizes[0]);
  };

  const calculateFinalPrice = (changedValue, otherValue) => {
    let finalPrice = productPrice.basePrice;
    let changedValueOpt = productPrice.aggregator.filter((a) =>
      a.options.find((o) => o._id === changedValue)
    );
    let otherValueOpt = productPrice.aggregator.filter((a) =>
      a.options.find((o) => o._id === otherValue)
    );

    let combinedOpt = changedValueOpt.filter((a) =>
      a.options.find((o) => o._id === otherValue)
    );

    if (combinedOpt.length > 0) {
      return combinedOpt[0].price;
    }

    let changedOption = changedValueOpt.find((p) => p.keys.length === 1);
    let otherOption = otherValueOpt.find((p) => p.keys.length === 1);

    if (changedOption && !otherOption) {
      finalPrice = changedOption.price;
    } else if (otherOption && !changedOption) {
      finalPrice = otherOption.price;
    } else if (changedOption && otherOption) {
      // this else if should not happen - is just for prevent bad configurations

      if (changedOption.price > otherOption.price) {
        finalPrice = changedOption.price;
      } else {
        finalPrice = otherOption.price;
      }
    }

    return finalPrice;
  };

  const setCalculetedFinalPrice = (changedValue, otherValue) => {
    let price = calculateFinalPrice(changedValue, otherValue);

    setFinalPrice(price);
  };

  const addToBag = () => {
    let propertiesSelected = [];

    if (
      canAddToBag &&
      !gridPicker.find((g) => g.quantity > 0 && checkIsMultiple(g.quantity))
    ) {
      if (size)
        propertiesSelected.push(
          ...product.properties.filter((p) => p._id === size._id)
        );

      if (color)
        propertiesSelected.push(
          ...product.properties.filter((p) => p._id === color._id)
        );

      handleAddToBag({
        product: {
          _id: product._id,
          title: product.title,
          image: product.remoteImage,
          price: finalPrice,
          properties: propertiesSelected,
          sizeVariant: sizeVariant !== undefined ? sizeVariant._id : null,
          colorVariant: colorVariant !== undefined ? colorVariant._id : null,
          lurexVariant: lurexVariant !== undefined ? lurexVariant._id : null,
        },
        quantity: quantity,
      });
    } else if (canAddToBag) {
      if (size && color) {
        propertiesSelected = product.properties.filter(
          (p) => p._id === size._id || p._id === color._id
        );
      }

      // if we have an combination on gridPicker
      // at least we should have 2 properties selected on normal select
      if (
        propertiesSelected.length > 0 &&
        quantity > 0 &&
        checkIsMultiple(quantity)
      ) {
        // adding a product in selected
        handleAddToBag({
          product: {
            _id: product._id,
            title: product.title,
            image: product.remoteImage,
            price: finalPrice,
            properties: propertiesSelected,
            sizeVariant: sizeVariant !== undefined ? sizeVariant._id : null,
            colorVariant: colorVariant !== undefined ? colorVariant._id : null,
            lurexVariant: lurexVariant !== undefined ? lurexVariant._id : null,
          },
          quantity: quantity,
        });
      }

      // check if we have any invalid quantity on gridPicker
      let invalidCombinedProp = gridPicker.filter(
        (g) =>
          g.quantity !== 0 &&
          g.quantity !== null &&
          !checkIsMultiple(g.quantity)
      );

      if (invalidCombinedProp !== undefined && invalidCombinedProp.length > 0) {
        Swal.fire(
          "Oops...",
          `Do you have ${invalidCombinedProp.length} combinations with a invalid quantity. Each quantity should be multiple of ${multipleSell}, please correct and try again.`,
          "warning"
        );

        return;
      }

      // add the combination products from gridPicker
      let validCombinedProperties = gridPicker.filter(
        (g) =>
          g.quantity > 0 && g.quantity !== null && checkIsMultiple(g.quantity)
      );

      for (let combination of validCombinedProperties) {
        handleAddToBag({
          product: {
            _id: product._id,
            title: product.title,
            image: product.remoteImage,
            price: calculateFinalPrice(combination.sizeId, combination.colorId),
            properties: product.properties.filter(
              (p) =>
                p._id === combination.sizeId || p._id === combination.colorId
            ),
            sizeVariant: sizeVariant !== undefined ? sizeVariant._id : null,
            colorVariant: colorVariant !== undefined ? colorVariant._id : null,
            lurexVariant: lurexVariant !== undefined ? lurexVariant._id : null,
          },
          quantity: combination.quantity,
        });
      }

      Swal.fire("Thank you!", "Product successfully added.", "success");

      navigate("/store");
    }
  };

  const handleGridPicker = (id, quantity) => {
    let picker = gridPicker;
    let isMultiple = checkIsMultiple(quantity);
    picker.find((g) => g.id === id).quantity = quantity;
    picker.find((g) => g.id === id).className =
      quantity === 0
        ? "bg-gray-100"
        : isMultiple
        ? "bg-blue-100"
        : "bg-yellow-500";

    setGridPicker(picker);
    setPickerChanged((prevState) => !prevState);
  };

  let labelPrice =
    productPrice.maxPrice === productPrice.basePrice
      ? convertToTwoDecimalCase(productPrice.basePrice)
      : `${convertToTwoDecimalCase(
          productPrice.basePrice
        )} - ${convertToTwoDecimalCase(productPrice.maxPrice)}`;

  let links = [
    ["/platform", "Home"],
    ["/store", "store"],
    [
      `/store/category/${convertToSlug(product.categories[0].name)}`,
      product.categories[0].name,
    ],
    [`/product/${product._id}`, product.title],
  ];

  let haveBothProperties =
    sizes && colors && sizes.length > 0 && colors.length > 0;

  let classNameForSelect =
    displayMode === "grid" && haveBothProperties ? "lg:hidden" : null;
  let classNameForGrid =
    displayMode === "grid" && haveBothProperties ? "lg:grid" : null;

  let imageContent = null;

  if (product.remoteImages && product.remoteImages.length > 1) {
    imageContent = <ImageCarousel images={product.remoteImages} />;
  } else if (product.remoteImage) {
    imageContent = (
      <div className="relative w-full h-300 lg:h-500">
        <Img
          fluid={product.remoteImage.childImageSharp.fluid}
          style={{ height: "100%", width: "100%" }}
          imgStyle={{ objectFit: "cover" }}
          className="object-cover rounded-lg h-300"
          alt="product image"
        />
      </div>
    );
  }

  return (
    <ShopLayout>
      <div className="border-b-2 border-dashed border-gray-300">
        <Breadcrumbs links={links} />
        <div className="container md:pl-12 mx-auto">
          <div
            className="grid grid-cols-1 lg:grid-cols-2 gap-2 lg:gap-6 lg:gap-y-4 py-10 lg:py-20"
            style={{
              gridTemplateRows: "auto 1fr",
            }}
          >
            <div className="order-2">
              <h1 className="font-display text-blue-900 text-3xl lg:text-4xl">
                {product.title}
              </h1>
              <div className="font-extrabold text-fuschia-700 text-2xl mb-1">
                {finalPrice > 0
                  ? convertToTwoDecimalCase(finalPrice)
                  : labelPrice}{" "}
                €
              </div>
            </div>
            <div className="order-last">
              <div
                className={`grid md:grid-cols-2 lg:grid-cols-3 leading-4 gap-4 md:gap-2 lg:gap-4 items-end ${classNameForSelect}`}
              >
                {colors && colors.length > 0 && (
                  <ColorSelect
                    label="Color"
                    open={openColorPicker}
                    setOpen={setOpenColorPicker}
                    select={handleColorChanged}
                    selected={color}
                    colors={colors}
                    lurexColors={lurexColors}
                  />
                )}
                {sizes && sizes.length > 0 && (
                  <Select
                    label="Size"
                    open={openSizePicker}
                    setOpen={setOpenSizePicker}
                    selected={size ? size._id : null}
                    placeholder={
                      size
                        ? size.slug === ""
                          ? size.value
                          : size.slug
                        : sizes.length === 1
                        ? sizes[0].slug === ""
                          ? sizes[0].value
                          : sizes[0].slug
                        : "Choose here"
                    }
                  >
                    {sizes.length > 1 && (
                      <BasicPicker items={sizes} select={handleSizeChanged} />
                    )}
                  </Select>
                )}
                <div className="flex items-center bg-gray-100 border pl-4 border-gray-300 rounded-md text-gray-700 leading-4 text-sm focus-within:border-gray-700 lg:col-span-2 xl:col-span-1">
                  <span>Quantity</span>
                  <span className="flex ml-auto rounded-r-md">
                    <button
                      type="button"
                      disabled={quantity <= minQuantity}
                      className="text-sm px-3 py-4 bg-gray-100"
                      onClick={() => {
                        setQuantity((quantity) => quantity - 1);
                      }}
                    >
                      {"<"}
                    </button>
                    <input
                      type="number"
                      min={3}
                      value={quantity}
                      onChange={(e) => setQuantity(Number(e.target.value))}
                      className="bg-gray-100 py-4 w-6 flex-shrink focus:outline-none rounded-r-md text-center appearance-none min-w-0"
                      required
                    />
                    <button
                      type="button"
                      className="text-sm px-3 py-4 bg-gray-100 rounded-r-md"
                      onClick={() => {
                        setQuantity((quantity) => quantity + 1);
                      }}
                    >
                      {">"}
                    </button>
                  </span>
                </div>
                <button
                  type="submit"
                  disabled={!canAddToBag}
                  onClick={addToBag}
                  className={`shadow block border py-4 rounded-md text text-white font-display lg:col-span-2 xl:col-span-1 ${
                    canAddToBag
                      ? "bg-fuschia-700 border-fuschia-700"
                      : "disabled:bg-blue-700 border-blue-700"
                  }`}
                >
                  Add to Bag
                </button>
                <div className="order-first lg:order-last md:col-span-3 flex flex-col space-y-2 text-gray-700 text-sm my-2 lg:mt-8">
                  <div>
                    <span className="text-blue-700">REF:</span>{" "}
                    {product.reference}
                  </div>
                  <div>
                    <span className="text-blue-700">Category:</span>{" "}
                    <Link
                      to={`/store/category/${convertToSlug(
                        product.categories[0].name
                      )}`}
                    >
                      {product.categories[0].name}
                    </Link>
                  </div>
                  {product.composition && (
                    <div className="flex space-x-1">
                      <span className="text-blue-700">Composition:</span>{" "}
                      <div>
                        {product.composition.split(";").map((line, i) => (
                          <p key={i}>{line}</p>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div
                className={`hidden md:grid-cols-2 lg:grid-cols-3 leading-4 gap-4 md:gap-2 lg:gap-4 items-end ${classNameForGrid}`}
              >
                <div className="order-first md:col-span-3 flex flex-col space-y-2 text-gray-700 text-sm">
                  <div>
                    <span className="text-blue-700">REF:</span>{" "}
                    {product.reference}
                  </div>
                  <div>
                    <span className="text-blue-700">Category:</span>{" "}
                    <Link
                      to={`/store/category/${convertToSlug(
                        product.categories[0].name
                      )}`}
                    >
                      {product.categories[0].name}
                    </Link>
                  </div>
                </div>
                <div className="order-last hidden lg:block">
                  <h2 className="font-display text-blue-900 text-xl lg:text-xl mb-4">
                    Size Guide
                  </h2>
                  <div className="text-sm">
                    <SizeGuide />
                  </div>
                  <button
                    type="submit"
                    disabled={!canAddToBag}
                    onClick={addToBag}
                    className={`shadow w-full mt-6 block border py-4 rounded-md text text-white font-display lg:col-span-2 xl:col-span-1 ${
                      canAddToBag
                        ? "bg-fuschia-700 border-fuschia-700"
                        : "disabled:bg-blue-700 border-blue-700"
                    }`}
                  >
                    Add to Bag
                  </button>
                </div>
              </div>
            </div>
            <div className="order-first row-span-2">{imageContent}</div>
          </div>
          <div className={`mb-16 ${classNameForSelect}`}>
            <h2 className="font-display text-blue-900 text-xl lg:text-xl mb-4">
              Size Guide
            </h2>
            <div className="text-sm">
              <SizeGuide />
            </div>
          </div>
          {displayMode === "grid" && haveBothProperties && (
            <table className="hidden lg:table mt-2 text-sm mb-10">
              {/**className="sticky bg-white w-full top-0 p-4 pb-0 z-20">*/}
              <thead>
                <tr>
                  <th className="text-left font-light text-gray-500 pl-5">
                    Size
                  </th>
                  {sizes.map((s) => (
                    <th key={s._id} className="text-center text-blue-700 p-1">
                      <div className="border-gray-700 align-middle border py-2 my-2 shadow-sm rounded">
                        {s.value}
                      </div>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className="text-left text-blue-900">
                {colors &&
                  colors.length > 0 &&
                  colors.map((c, i) => (
                    <React.Fragment key={`${c._id}-${i}`}>
                      {i === 0 && (
                        <tr
                          key={`${i}-${c._id}`}
                          className="bg-gray-500 text-white"
                        >
                          <td className="pl-5 pr-5">Price</td>
                          {sizes.map((s) => (
                            <td
                              key={`${s._id}-${i}-${c._id}`}
                              className="align-middle text-sm text-center py-2"
                            >
                              {convertToTwoDecimalCase(
                                calculateFinalPrice(s._id, c._id)
                              )}{" "}
                              €
                            </td>
                          ))}
                        </tr>
                      )}
                      <tr
                        key={c._id}
                        className="font-display text-left text-blue-900"
                      >
                        <td className="w-1 pl-2 tooltip">
                          <img
                            src={c.image.secure_url}
                            width={55}
                            height={55}
                            loading="lazy"
                            className="object-cover rounded-full shadow-md transform hover:scale-150"
                            alt={c.value}
                          />
                          <span className="tooltip-text ml-16 font-display text-base text-blue-900">
                            {c.value} - {c.slug}
                          </span>
                        </td>
                        {sizes.map((s) => {
                          let key = `${s._id}${c._id}`;
                          let square = gridPicker.find((g) => g.id === key);
                          if (square === undefined)
                            gridPicker.push({
                              id: key,
                              sizeId: s._id,
                              colorId: c._id,
                              quantity: null,
                              className: "bg-gray-100",
                            });

                          return (
                            <td
                              key={key}
                              className="font-normal text-center py-2 w-20 pl-3"
                            >
                              <input
                                type="number"
                                className={`${
                                  square ? square.className : "bg-gray-100"
                                } w-24 h-14 focus:outline-none rounded text-center shadow`}
                                min="3"
                                onInput={(e) => {
                                  handleGridPicker(key, Number(e.target.value));
                                }}
                              />
                            </td>
                          );
                        })}
                      </tr>
                    </React.Fragment>
                  ))}
                {lurexColors &&
                  lurexColors.length > 0 &&
                  lurexColors.map((c, i) => (
                    <React.Fragment key={`${c._id}-${i}`}>
                      {i === 0 && (
                        <React.Fragment key={`${i}-${c._id}`}>
                          <tr className="pb-8">
                            <td className="pl-5 pr-5 pb-2 pt-2 text-sm font-light text-gray-500">
                              Lurex
                            </td>
                            {sizes.map((s) => (
                              <td key={`${s._id}-${i}-${c._id}`}>
                                <div className="h-1 border-gray-300 border-b" />
                              </td>
                            ))}
                          </tr>
                          <tr className="bg-gray-500 text-white">
                            <td className="pl-5 pr-5">Price</td>
                            {sizes.map((s) => (
                              <td
                                key={`${s._id}-${i}-${c._id}`}
                                className="align-middle text-sm text-center py-2"
                              >
                                {convertToTwoDecimalCase(
                                  calculateFinalPrice(s._id, c._id)
                                )}{" "}
                                €
                              </td>
                            ))}
                          </tr>
                        </React.Fragment>
                      )}
                      <tr
                        key={c._id}
                        className="font-display text-left text-blue-900"
                      >
                        <td className="w-1 pl-2 tooltip">
                          <img
                            src={c.image.secure_url}
                            width={55}
                            height={55}
                            loading="lazy"
                            className="object-cover rounded-full shadow-md transform hover:scale-150"
                            alt={c.value}
                          />
                          <span className="tooltip-text ml-16 font-display text-base text-blue-900">
                            {c.value} - {c.slug}
                          </span>
                        </td>
                        {sizes.map((s) => {
                          let key = `${s._id}${c._id}`;
                          let square = gridPicker.find((g) => g.id === key);
                          if (square === undefined)
                            gridPicker.push({
                              id: key,
                              sizeId: s._id,
                              colorId: c._id,
                              quantity: null,
                              className: "bg-gray-100",
                            });

                          return (
                            <td
                              key={key}
                              className="font-normal text-center py-2 w-20 pl-3"
                            >
                              <input
                                type="number"
                                className={`${
                                  square ? square.className : "bg-gray-100"
                                } w-24 h-14 focus:outline-none rounded text-center shadow`}
                                min="3"
                                onInput={(e) => {
                                  handleGridPicker(key, Number(e.target.value));
                                }}
                              />
                            </td>
                          );
                        })}
                      </tr>
                    </React.Fragment>
                  ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </ShopLayout>
  );
};

export default Product;

export const query = graphql`
  query Product($_id: String!) {
    product(_id: { eq: $_id }) {
      _id
      title
      reference
      composition
      categories {
        _id
        name
      }
      price {
        basePrice
        maxPrice
        aggregator {
          price
          options {
            _id
            relationKey
          }
          keys {
            _id
          }
        }
      }
      configurations {
        updatedAt
        item {
          referenceId
          value {
            basePrice
            maxPrice
            aggregator {
              price
              options {
                _id
                relationKey
              }
              keys {
                _id
              }
            }
          }
        }
        groups {
          _id
        }
      }
      properties {
        image {
          secure_url
        }
        value
        slug
        relationKey
        _id
      }
      variants {
        name
        _id
      }
      remoteImage {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 50) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      remoteImages {
        url
      }
      optionFields {
        _id
        name
        value
      }
    }
  }
`;
